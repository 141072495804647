import { ModuleWithProviders, NgModule } from '@angular/core';

import { DecimalPipe } from './decimal.pipe';
import { LocaleDatePipe } from './locale-date.pipe';
import { TimePipe } from './time.pipe';

@NgModule({
  imports: [TimePipe, DecimalPipe, LocaleDatePipe],
  providers: [DecimalPipe],
})
export class SharedPipeModule {
  public static forRoot(): ModuleWithProviders<SharedPipeModule> {
    return {
      ngModule: SharedPipeModule,
    };
  }
}
